import React from "react";
import "../App.css"

function Home() {
  return (
    <div className="HomeConatiner">
      <img src="/myginneLogo.png" alt="Logo" />
    </div>
  );
}

export default Home;
