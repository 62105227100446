import React, { useEffect, useState } from "react";
import { apiRequest } from "./apiCall";
import CreatePDFInfo from "./PDFReceipt";
import "../App.css";
import { useParams } from "react-router-dom";
import NoOrderFound from "./NoOrderFound";

function Receipt() {
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false); // Add loading state
  const { uniq_code_pos } = useParams();

  console.log("uniq_code_pos", uniq_code_pos);

  useEffect(() => {
    (async () => {
      setLoading(true); // Start loading
      try {
        const data = await apiRequest("POST", "/ReceiptOrderdetailsUniq", {
          uniq_code_pos: uniq_code_pos,
        });
        if (data?.result?.state === "success") {
          console.log("Response of order", data?.result);
          setData(data.result);
        } else {
          setData(null); // Ensure data is null if not successful
        }
      } catch (err) {
        console.error(err);
        setData(null); // Ensure data is null on error
      } finally {
        setLoading(false); // End loading whether or not there was an error
      }
    })();
  }, [uniq_code_pos]); // Ensure effect runs when uniq_code_pos changes

  return (
    <div className="Receiptcontainer">
      {isLoading ? (
        <div className="loading">
          <div className="loader"></div>
        </div>
      ) : data ? (
        <CreatePDFInfo data={data} /> // Display this when not loading and data is available
      ) : (
        <NoOrderFound /> // Display this when not loading and no data is found
      )}
    </div>
  );
}

export default Receipt;
