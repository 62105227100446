import React from "react";
import "../App.css";
const NoOrderFound = () => {
  return (
    <div className="no-order-container">
      <div className="no-order-content">
        <h1>Oops!</h1>
        <p>No order found.</p>
      </div>
    </div>
  );
};

export default NoOrderFound;
