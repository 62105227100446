import React from "react";
import axios from "axios";
import { BASE_URL } from "../utils/config";

const axiosAPI = axios.create({
  baseURL: BASE_URL,
});

export const apiRequest = async (method, url, data = null, headers = null) => {
  try {
    console.log("methndddsss", data, headers);
    const res = await axiosAPI({
      method,
      url,
      data,
      headers,
    });

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export default axiosAPI;
