import "./App.css";
import React from "react";
import Receipt from "./components/Receipt";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {" "}
          {/* Use Routes to wrap Route components */}
          <Route path="/" element={<Home />} /> {/* Use element prop */}
          <Route path="/:uniq_code_pos" element={<Receipt />} />
          {/* Use element prop */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
